import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Resource, AdminContext, AdminUI, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

// import { createPouchAuth } from '../../couch/pouchDbAPI';
import { siteConfig } from '../../isomorphic/config';
// import couchConfig from '../../couch/config';
import checksheet from '../Checksheet';
import checksheetConnection from '../Checksheet/ChecksheetConnect';
import { REPOSITORY_STATE } from '../../couch/sync-manager';

import { login, session, logout } from './api/authApi';
import { CouchDbReplicaProviderAppContext } from './CouchDbReplicaProviderAppContext';
import i18nProvider from './i18nProvider';
import { createAuthProvider } from './authProvider';
import { defaultDataProvider } from './dataProvider/couchProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import punches from './punches';
import preservation from './preservation';
import { useDb } from './hooks';
import ScrollToTop from './ScrollToTop';
import CloudWatchLogger from './CloudWatchLogger';
import { WithAppSettingsProvider } from './layout/AppSettingsProvider';
import { WithAppSettingsThemeProvider } from './layout/AppSettingsThemeProvider';
import AppSettingsContext from './layout/AppSettingsContext';
import ComplanAdminProvider from './layout/ComplanAdminProvider';
const ThemedLogin = WithAppSettingsThemeProvider({
  Component: Login,
  defaultTheme: true,
});

const HierRoot = loadable(() => import('./hierarchy/HierRoot'));

const complanRoutes = {
  autopl: punches,
  core: {},
  hier: {
    list: HierRoot,
    edit: HierRoot,
    show: HierRoot,
  },
  conpmr: preservation,
  checksheet_questions: checksheet,
  checksheet_headers: checksheet,
  checksheet_footers: checksheet,
  checksheet_form: checksheet,
  checksheet,
  con_checksheet_form: checksheetConnection,
  con_checksheet: checksheetConnection,
  con_checksheet_equipment: checksheetConnection,
  refpmr: {},
};

const routes = Object.keys(complanRoutes).map(r => (
  <Resource key={r} name={r} {...complanRoutes[r]} />
));

// eslint-disable-next-line react/prop-types
const CoreSymtGate = props => {
  const { allTables } = useContext(CouchDbReplicaProviderAppContext);
  const redirect = useRedirect();
  const location = useLocation();

  const { db } = useDb(['symt'], REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED);

  const redirectCheck = () => {
    if (!location || !location.pathname) {
      return;
    }
    const { pathname } = location;

    // Allowed redirects regardless of core or symt dbs, currently only one.
    const allowedRedirects = ['/login'];

    const allowedRedirect = allowedRedirects.includes(pathname);

    if (allowedRedirect) {
      return;
    }

    /**
     * allTables can have three states -
     * undfined - yet to have conclusive result,
     * empty array - no previous session cached results or current session yet synced results
     * full array - context completed loading from symt db list of tables
     * Only in case we have conclusive empty array result we will redirect to bootstrap, at any other case we will do nothing (till var would be updated)
     * */
    const allTablesEmpty =
      allTables && Array.isArray(allTables) && allTables.length === 0;
    const bootstrapRedirect =
      allTablesEmpty && !db && !pathname.includes('bootstrap');
    if (bootstrapRedirect) {
      redirect('/bootstrap');
    }
  };

  useEffect(
    () => {
      redirectCheck();
    },
    [db, location],
  );

  useEffect(() => {
    redirectCheck();
  }, []);

  return props.children;
};

CoreSymtGate.propTypes = {
  children: PropTypes.node.isRequired,
};

// const { url } = couchConfig;
// const pouchAuth = createPouchAuth({ remoteServer: url });
const directApiAuth = { login, getSession: session, logout };
const authProviderInitialized = createAuthProvider({ authApi: directApiAuth });

const ComplanReactAdmin = ({ history }) => {
  const { allTables, contextRepositories } = useContext(
    CouchDbReplicaProviderAppContext,
  );
  const [resources, setResources] = useState(routes);

  const { theme } = useContext(AppSettingsContext);

  useEffect(
    () => {
      if (!allTables) {
        return;
      }

      const complanRoutesKeys = Object.keys(complanRoutes);
      const allTablesNoDuplicateRoutes = allTables.filter(
        table => !complanRoutesKeys.includes(table),
      );
      const resourcesNoDuplicates = allTablesNoDuplicateRoutes.map(t => (
        <Resource name={t} key={`resource-${t}`} />
      ));
      const aggregatedRoutes = routes.concat(resourcesNoDuplicates);
      setResources(aggregatedRoutes);
    },
    [allTables],
  );

  useEffect(
    () => {
      defaultDataProvider.setDataSource(contextRepositories);
    },
    [contextRepositories],
  );
  // We set ThemeProvider besides passing {theme} to AdminUI because custom pages/routes do not receive theme from AdminUI by default and you must set their {theme} manually, we on the other hand, wish to have a single theme common theme for both.
  return (
    <AdminContext
      title={siteConfig.siteName}
      dataProvider={defaultDataProvider}
      authProvider={authProviderInitialized}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      history={history}
    >
      <ComplanAdminProvider>
        <CloudWatchLogger />
        <CoreSymtGate>
          <AdminUI
            loginPage={ThemedLogin}
            layout={Layout}
            dashboard={Dashboard}
            customRoutes={customRoutes}
            theme={theme}
          >
            <ScrollToTop />
            {resources}
          </AdminUI>
        </CoreSymtGate>
      </ComplanAdminProvider>
    </AdminContext>
  );
};

ComplanReactAdmin.propTypes = {
  history: PropTypes.any,
};

const WithAppSettingsComplanReactAdmin = WithAppSettingsProvider()(
  ComplanReactAdmin,
);
export default WithAppSettingsComplanReactAdmin;
