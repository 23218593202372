import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';

import ComplanAdminContext from './ComplanAdminContext';

export const ComplanAdminProvider = ({ children }) => {
  const history = useHistory();
  const previousUrlRef = useRef(null);
  const currentUrlRef = useRef(null);

  useEffect(
    () => {
      const unlisten = history.listen(newLocation => {
        const newUrl = `${newLocation.pathname}${newLocation.search}`;
        if (newUrl !== currentUrlRef.current) {
          previousUrlRef.current = currentUrlRef.current;
          currentUrlRef.current = newUrl;
        }
      });

      return () => unlisten();
    },
    [history],
  );

  /**
   * For complete control pass metadata with history.push for proper back navigation, this is intended for cases where it is hard/impossible to add this metadata (e.g overrides required)
   * Initial basic implementation.
   * Use with caution as the interface exposed by history is limited, thoroughly test per case, it does not imitate browser 'back' 1:1.
   */
  const navigateBack = useCallback(
    () => {
      if (previousUrlRef.current) {
        history.push(previousUrlRef.current);
      }
    },
    [history],
  );

  return (
    <ComplanAdminContext.Provider value={{ navigateBack }}>
      {children}
    </ComplanAdminContext.Provider>
  );
};

export default ComplanAdminProvider;
